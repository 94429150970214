import { getCell, getCellAddress, getColumnHeaderText } from "@syncfusion/ej2-spreadsheet";
import { useEffect, useState } from "react";
import { AdminColumnStyle, AutomaticColumnStyle, ColumnHeaderStyle, ColumnStyle, RequiredColumnStyle } from '../styles/SettingAndStyles';
import { GetClassProperties, GetPropertyDetails } from "../pages/MFiles/MFilesService";

export function getLastEmptyRow(spreadsheet: any){
  const sheet = spreadsheet.getActiveSheet();
  const rowCount = sheet.usedRange.rowIndex;
  
  let lastEmptyRow = rowCount + 1; // Start with the next row after the last used row
  for (let i = rowCount; i >= 0; i--) {
    const row = sheet.rows[i];
    const isEmpty = row?.cells?.every(cell => !cell?.value);
    if (isEmpty) {
      lastEmptyRow = i;
    } else {
      break; // Exit loop when a non-empty row is found
    }
  }
  return lastEmptyRow;
}

export function IsColumnExist(spreadsheet: any, colName: any){
  let usedColIdx = spreadsheet?.getActiveSheet()?.usedRange?.colIndex!;
//console.log('IsColumnExist', colName, spreadsheet.getActiveSheet()?.name, usedColIdx)
  const firstColIdx = 0;  
  for (let colIdx = firstColIdx; colIdx <= usedColIdx; colIdx++) {
    const cell = getCell(0, colIdx, spreadsheet?.getActiveSheet()); 
    if (cell?.value === colName)
      return true;
  }
  return false;
}

export const addObligatoryColumns = async (spreadsheet: any, clsId: any) => {
  let sheetName = spreadsheet?.getActiveSheet().name;
  let data = await GetClassProperties(clsId);
  let namePropertyDef = data?.NamePropertyDef;  //gives the ID of the name property column
  if (data === undefined) return;
  let index = 1;
  await Promise.all(
    data?.AssociatedPropertyDefs?.map(async (element: any) => { 
      if (element.Required == true ){
        let prop = await GetPropertyDetails(element.PropertyDef);
        if (element.PropertyDef == namePropertyDef && IsColumnExist(spreadsheet, prop.Name) == false ){   //fill Name column as first column
          spreadsheet?.insertColumn([{ index: 0, width: 120 }], 0);
          spreadsheet?.updateCell(
            {
              value: prop.Name,
              style: ColumnHeaderStyle
            },
            getCellAddress(0, 0)
          );
          if (window.ReorderColumnHeaders?.filter(x => x.SheetName == sheetName).length > 0){
            var item = {Title: prop.Name, Hidden: false, Visible: true, Key: "", IsAdmin: false, PropertyLabel: "O", SheetName: sheetName};
            window.ReorderColumnHeaders?.splice(0, 0, item);
          }
        }
        else{
          if (!prop.Predefined && IsColumnExist(spreadsheet, prop.Name) == false || (prop.Predefined && prop.ID == 0)){
            spreadsheet?.insertColumn([{ index: index, width: 120 }], index);
            spreadsheet?.updateCell(
              {
                value: prop.Name,
                style: ColumnHeaderStyle
              },
              getCellAddress(0, index)
            );
            //check whether ReorderColumnHeaders is already filled
            if (window.ReorderColumnHeaders?.filter(x => x.SheetName == sheetName).length > 0){
              var item = {Title: prop.Name, Hidden: false, Visible: true, Key: "", IsAdmin: false, PropertyLabel: "O", SheetName: sheetName};
              window.ReorderColumnHeaders?.splice(index, 0, item);
            }
            index ++;
          }
        }
      }
    })
  
 
 
);
  //let props = window.ExistingPropIds?.filter(x => x.SheetName == sheetName && x.Required == true);
  //console.log(props);
  // props?.forEach((col, index) => {
  //   if (window.ColumnHeaders?.indexOf(col.Name) == -1){   //column not present in the sheet
  //     spreadsheet?.insertColumn(index);
  //     spreadsheet?.updateCell(
  //       {
  //         value: col.Name,
  //         style: ColumnHeaderStyle
  //       },
  //       getCellAddress(0, index )
  //     );
  //     window.ColumnHeaders?.splice(index, 0, col.Name);
  //     var reorderIndex = window.ReorderColumnHeaders?.findIndex(
  //       (x) => x.SheetName === sheetName && x.Title === col.Name
  //     )!;
  //     if (reorderIndex !== -1){
  //           window.ReorderColumnHeaders?.splice(reorderIndex, 1); // 2nd parameter means remove one item only
  //     }
  //     var item = {Title: col.Name, Hidden: false, Visible: true, Key: "", IsAdmin: false, PropertyLabel: "O", SheetName: sheetName};
  //     window.ReorderColumnHeaders?.splice(index, 0, item);
  //   }
  // });
}

export async function addAdminColumnHeaders(spreadsheet: any){
  let usedColIdx = spreadsheet?.getActiveSheet().columns.length - 1;
  if (usedColIdx != 0)
  {
    usedColIdx += 1;
  }
  window.RequiredAdminColumns?.forEach((col, index) => {
    spreadsheet?.updateCell(
      {
        value: col,
        style: ColumnHeaderStyle
      },
      getCellAddress(0, usedColIdx + index )
    );
    // added in arrange columns values
    window.isAdminColumnsAddedForTrans = true
  });
}


export async function updateCell(spreadsheet: any, rowIndex: any, colIndex: any, value: any) {
  if (colIndex < 0) return;
  return spreadsheet?.updateCell({
      value,
      style: ColumnStyle,
  }, getCellAddress(rowIndex, colIndex));
}

export async function adjustColumnWidths(spreadsheet: any) {
  const usedColIdx = spreadsheet?.getActiveSheet().usedRange?.colIndex || 0;
  const colHeaderText = getColumnHeaderText(usedColIdx + 1);
  const columnRange = `${getColumnHeaderText(1)}:${colHeaderText}`;
  spreadsheet?.setColumnsWidth(120, [columnRange]);
 // spreadsheet?.enableToolbarItems("View", [8], true);
}

export function getSheetIndex(spreadsheet: any, sheetName: any) {
  let sheetIndex = spreadsheet?.sheets.findIndex(x => x.name === sheetName);
  return sheetIndex;
  //return sheet?.id ? sheet.id - 1 : undefined;
}

export async function updateSheetCells( spreadsheet: any, objTypeId: any, objTypeName: any, clsId: any, clsName: any, lastEmptyRow: any, usedColIdx: any ) {
  if (lastEmptyRow < 1) lastEmptyRow = 1; //just add one row for recording the ObjectType, ObjectClass
 // usedColIdx = spreadsheet?.getActiveSheet().columns.length - 1;
  if (usedColIdx != 0)  //if any column is already present..adding 1 to the existing column index
    {
      usedColIdx += 1;
    }
  for (let i = 1; i <= lastEmptyRow; i++) {
      await updateCell(spreadsheet, i, window.ColumnHeaders?.indexOf("Object Type"), objTypeId);
      await updateCell(spreadsheet, i, window.ColumnHeaders?.indexOf("Object Name"), objTypeName);
      await updateCell(spreadsheet, i, window.ColumnHeaders?.indexOf("Object Class"), clsId);
      await updateCell(spreadsheet, i, window.ColumnHeaders?.indexOf("Class Name"), clsName);
      await updateCell(spreadsheet, i, window.ColumnHeaders?.indexOf("Modified"), "FALSE");
      await updateCell(spreadsheet, i, window.ColumnHeaders?.indexOf("Update Status"), "FALSE");
      await updateCell(spreadsheet, i, window.ColumnHeaders?.indexOf("Updated Message"), "");
  }
}

export async function updateExistingObject(spreadsheet: any, rowIndex: any, item: any) {
  await updateCell(spreadsheet, rowIndex, window.ColumnHeaders?.indexOf("GUID"), item.ObjectGUID);
  await updateCell(spreadsheet, rowIndex, window.ColumnHeaders?.indexOf("ID"), item.ObjVer.ID);
  await updateCell(spreadsheet, rowIndex, window.ColumnHeaders?.indexOf("Modified"), "True");
}

export async function updateNewObject(spreadsheet: any, rowIndex: any) {
  if (rowIndex < 1) rowIndex = 1; //just add one row for recording the ObjectType, ObjectClass
  await updateCell(spreadsheet, rowIndex, window.ColumnHeaders?.indexOf("ID"), "-1");
}

export function hideAdminColumns(spreadsheet: any) {
  window.ReorderColumnHeaders?.forEach((element) => {
    console.log('Hiding Admin Columns');
    if (element.IsAdmin === true && window.ColumnHeaders?.indexOf(element.Title) !== -1) {
      let index = window.ColumnHeaders?.indexOf(element.Title);
      spreadsheet?.hideColumn(index, index, true);
      element.Hidden= true;
    }      
  });
}

export function renameSheetTab(spreadsheet, sheetName)
{
  for (let i = 1; i <= spreadsheet?.sheets.length + 1; i ++){
    var temp = sheetName + "_" + i;
    if (spreadsheet?.sheets?.find(x => x.name == temp.trim()) === undefined)
    {
      return temp;
    }
  }  
}

export function markObligatoryProperties(isNewRow: boolean = false, isRearrange: boolean = false, spreadsheet: any) { 
  let sheetName = spreadsheet?.getActiveSheet().name;
  let usedRowIdx = getLastEmptyRow(spreadsheet) ;
  if (isRearrange) {
    usedRowIdx = usedRowIdx + 1;
  }
  if (usedRowIdx == 1) return;
  window.ExistingPropIds?.forEach((prop) => {
    var index = window.ColumnHeaders?.indexOf(prop.Name);
    if (prop.Required && index !== -1) {
      let colHeaderText = getColumnHeaderText(index + 1);
      spreadsheet?.cellFormat(
        RequiredColumnStyle, //Blue
        sheetName + "!" + colHeaderText + 2 + ":" + colHeaderText + usedRowIdx
      );
    }
    if (prop.AutomaticValueType != 0 && index !== -1) {
      let colHeaderText = getColumnHeaderText(index + 1);
      spreadsheet?.cellFormat(
        AutomaticColumnStyle, //Gray
        sheetName + "!" + colHeaderText + 2 + ":" + colHeaderText + usedRowIdx );
    }
  });
 // console.log(styleData)
  // window.ReorderColumnHeaders?.forEach((element) => {
  //   var index = window.ColumnHeaders?.indexOf(element.Title);
  //   if (element.IsAdmin == true && index !== -1) {
  //     let colHeaderText = getColumnHeaderText(index + 1);
  //     console.log(element.Title)
  //     console.log("Admin Column");
  //     console.log(colHeaderText)
  //     spreadsheet?.cellFormat(
  //       AdminColumnStyle,
  //       sheetName + "!" + colHeaderText + 2 + ":" + colHeaderText + usedRowIdx
  //     );
  //   }
  // });

}
