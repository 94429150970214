import React, { useEffect, useState } from "react";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import "../styles/Login.scss";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import checked from "../assets/Icons/check-circle-fill.svg";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { GetClassObjects, GetClasses, GetServerStatus, GetToken, GetVaults } from "./MFiles/MFilesService";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import "../styles/ServerConnection.scss"

const ServerConnection = () => {
  const [server, setServer] = useState("");
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  var isAuthorized = sessionStorage.getItem("isAuthorized");
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [vaultListData, setVaultListData] = useState([]);
  const [vaultList, setVaultList] = useState([]);
  const [clsName, setClsName] = useState("");
  const [clsId, setClsId] = useState("");
  const [viewReportListData, setViewReportListData] = useState([]);
  const [viewReportList, setViewReportList] = useState([]);
  const [selectedVault, setSelectedVault] = useState("");
  const [selectedObject, setSelectedObject] = useState("");
  let vaultListObject: any;
  var classListData: any;
  var viewReportListObject: any;
  var list = { text: "Name", value: "GUID" };
  var list1 = { text: "Title", value: "ObjVer.ID" };
  var fileId: any;
  var token = localStorage.getItem("token") as string;
  var servertoken: any;
  var server1 = sessionStorage.getItem("host");
  servertoken = sessionStorage.getItem("servertoken") as string;
  localStorage.setItem("authType", "MFiles");
  window.Host = server + "/REST/";
 const storedValue = sessionStorage.getItem('vaultId');
 const storedText = sessionStorage.getItem('vaultName');

 const storedDocId = sessionStorage.getItem('docId');
 const storedFileName = sessionStorage.getItem('fileName');

  const [vaultId, setVaultId] = useState("");
  const [vaultName, setVaultName] = useState("");
  const [docId, setDocId] = useState("");
  const [docName, setDocName] = useState("");

  useEffect(() => {
    if (sessionStorage.getItem("host") !== "")
      setServer(sessionStorage.getItem("host") as string);
    if (sessionStorage.getItem("user") !== "")
      setUser(sessionStorage.getItem("user") as string);
    if (sessionStorage.getItem("vaultId") !== "") 
      vaultListObject!.value = sessionStorage.getItem("vaultId");
      if (sessionStorage.getItem("clsName") !== "") {
        setClsName(sessionStorage.getItem("clsName") as string);
    }   
  }, []);

  useEffect(() => {
    if (servertoken !== null && servertoken !== "null" && servertoken !== "" && server1 !== null && server1 !== "null") {
      fetchVaults("");
    }
  }, [token]);

  const onServerChange = (event: { value: React.SetStateAction<string>; }) => {
    setServer(event.value);     
    window.Host = server + "/REST/";
    setIsError(false);
    setErrorMsg("");
  };

  const onUserChange = (event: { value: React.SetStateAction<string>; }) => {
    setUser(event.value);
    setIsError(false);
    setErrorMsg("");
  };

  const onPwdChange = (event: { value: React.SetStateAction<string>; }) => {
    setPassword(event.value);
    setIsError(false);
    setErrorMsg("");
  };

  // for validate server address
  const handleServerChange = () => {
    const regex = /^(http|https):\/\/[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValidUrl = regex.test(server);

    if (!isValidUrl) {
      setIsError(true);
      setErrorMsg(
        "The server address is not valid, it should start with http or https."
      );
    } else {
      setIsError(false);
      setErrorMsg("");
    }
  };

  const onDocChange = (args: any) => {
    //docId = args?.itemData?.ObjVer.ID;
    setDocId(args?.itemData?.ObjVer.ID);
    fileId = args?.itemData?.Files[0]?.ID;
    sessionStorage.setItem("docId", args?.itemData?.ObjVer.ID);
    sessionStorage.setItem("fileId", fileId);
    sessionStorage.setItem("fileName", args?.itemData?.Title);
  };

  async function TestConnectionToServer() {
    
    const regex = /^(http|https):\/\/[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValidUrl = regex.test(server);
    if (!isValidUrl) {
      setIsError(true);
      setErrorMsg(
        "The server address is not valid, it should start with http or https."
      );
    }
    else {
      try{
        let data = await GetServerStatus();
        if (data.Successful !== true) {
          setIsError(true);
          setErrorMsg("Connection failed.");
          return;
        }
        else {
          setIsError(false);
          setErrorMsg("");
          toast.success("Connection successful!");
        }
      }
      catch (e: any) {
        setIsError(true);
        setErrorMsg("Connection failed. Please check the server address.");
      }
    }
  }

  async function ConnectToServer() {
    window.Host = server + "/REST/";
    sessionStorage.setItem("host", server); 
    const regex = /^(http|https):\/\/[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    var serverUrl = server.endsWith("/") ? server.substring(0, server.length - 1) : server;
    const isValidUrl = regex.test(serverUrl);

    //vaultListObject!.dataSource = [];
    if (server === "" || server === null || user === "" || password === "") {
      setIsError(true);
      setErrorMsg("Please enter all the fields");
    } else if (!isValidUrl) {
      setIsError(true);
      setErrorMsg(
        "The server address is not valid, it should start with http or https."
      );
    } else {
      setIsError(false);
      setErrorMsg("");
      // sessionStorage.setItem("host", server);
      // sessionStorage.setItem("server", server);
       sessionStorage.setItem("user", user);
      // sessionStorage.setItem("pwd", password);
      var vaultId = "";
      var data;
      var authObject = {
        username: user,
        password: password,
        VaultGuid: vaultId,
      };
      try {
        data = await GetToken(authObject);
      } catch (e: any) {
        if (e.message === "Failed to fetch") {
          setIsError(true);
          setErrorMsg("Error in connecting to the server.");
        } else {
          setIsError(true);
          setErrorMsg(e.message);
        }

        setIsLoading(false);
        sessionStorage.setItem("isServerConnect", "true");
        sessionStorage.setItem("server", "null");
        navigate("/serverconnection");
        return;
      }
      sessionStorage.setItem("isAuthorized", "true");
      localStorage.setItem("redirect", "false");
      sessionStorage.setItem("servertoken", data.Value);
      //localStorage.setItem("token", data.Value);
      localStorage.setItem("loggedInUser", user!);
      fetchVaults("connectToServer");
    }
  }

 // const fetchVaults =async(typeCheck) => {
  async function fetchVaults(typeCheck) {
    token = localStorage.getItem("token") as string;
    servertoken = sessionStorage.getItem("servertoken") as string;
    window.Host = sessionStorage.getItem("host") + "/REST/";
    if (servertoken != null && servertoken !== "null") {
      setIsLoading(true);
      let data =  await GetVaults(servertoken);
      if (data.Status === 403) {
        setIsLoading(false);
        setIsError(true);
        setErrorMsg("Invalid user name or password");
        sessionStorage.setItem("isAuthorized", "false");
        sessionStorage.setItem("isServerConnect", "true");
        sessionStorage.setItem("server", "null"); 
        //navigate("/");
        return;
      }
      if (data?.Status === 500 && data?.ExceptionName === "CombinedAuthenticationException"){
        setIsLoading(false);
        setIsError(true);
        setErrorMsg("You cannot log in because your account has not been assigned a license.");
        sessionStorage.setItem("isAuthorized", "false");
        sessionStorage.setItem("isServerConnect", "true");
        sessionStorage.setItem("server", "null"); 
        return;

      }
      if (data?.Status !== undefined && data.Status !== 200) {
        setIsLoading(false);
        sessionStorage.setItem("isServerConnect", "true");
        sessionStorage.setItem("server", "null");
        navigate("/");
        return;
      }
      if (data) {
        if (typeCheck === "connectToServer") {
          toast.success("Server is connected successfully!");
        }
        sessionStorage.setItem("isAuthorized", "true");
        setVaultListData(data);
        vaultListObject = [];
        if (vaultListObject != null) {
          vaultListObject!.fields = list;
          vaultListObject!.dataSource = data;
        }
        setVaultId(storedValue!);
        setVaultName(storedText!);
        // if (
        //   sessionStorage.getItem("vaultId") !== "null" &&
        //   vaultListObject != null
        // ) {
        //   vaultListObject.value = sessionStorage.getItem(
        //     "vaultId"
        //   ) as string;
        //   vaultListObject.text = sessionStorage.getItem(
        //     "vaultName"
        //   ) as string;
        // }
        setIsLoading(false);
      }      
    }
  };

  async function onVaultChange(args: any) {
    setIsLoading(true);
    var clsExist: boolean = false;
    if (args.itemData != null) {
      var vaultId = args.itemData!.GUID;
      var authObject = {
        username: user,
        password: password,
        VaultGuid: vaultId,
      };

       //if selected vault is different from previous vault then get auth token for selected vault        
      if ((vaultId !== sessionStorage.getItem("vaultId") || server1 !== sessionStorage.getItem("host")) ) {
        if (server === "" || server === null || user === "" || password === "") {
          setIsError(true);
          setErrorMsg("Please enter all the fields");
          return;
        }
        let data1 = await GetToken(authObject);   //Get auth token for selected vault to fetch data
        localStorage.setItem("token", data1.Value);
        sessionStorage.setItem("IsMFilesConnected", "true");
        //window.IsMFilesConnected = true;
      }
      sessionStorage.setItem("vaultId", args.itemData.GUID);
      sessionStorage.setItem("vaultName", args.itemData.Name);
      setClsName("");
      setClsId("");
      let data = await GetClasses(0);
      classListData = data;
     
      if (data != null && data.length > 0) {
        data?.forEach(function (item, i) {
          if (item.Name == "View Report") {
            clsExist = true;
            changeHandler3(item.ID, item.Name);
          }
        });
        if (!clsExist) {
          setViewReportListData([]);
          alert("View Report class not found in selected vault");
        }
      }
      setIsLoading(false);
    }
  }

  const changeHandler3 = (clsId, clsName) => {
    setClsId(clsId);
    setClsName(clsName);
    setIsLoading(true);
    sessionStorage.setItem("clsName", clsName);

    GetClassObjects(0, clsId).then((data) => {
        setViewReportListData(data.Items);
        viewReportListObject = [];
        if (viewReportListObject != null) {
          viewReportListObject!.fields = list1;
          viewReportListObject!.dataSource = data.Items;
        }
       setDocId(sessionStorage.getItem("docId")!);
       setDocName(sessionStorage.getItem("fileName") as string);
        setIsLoading(false);
      });
  };


  const OpenDocument = () => {
    navigate("/");
  };

  return (
    <div className="server-connection-main">
        <div className="form--user__icon">
          <div className="icon--img">
            <span className="glyphicon glyphicon-user">
              <h1 className="connect-to-m-file">M</h1>
            </span>
          </div>
        </div>
        <h4 className="text-center text-m-files">
          Welcome To <span className="span-m-file">M-Files!</span>
        </h4>
        <div className="container-box">
          <div className="server-address-connect">
            <div className="form-group mb-2">
              <label htmlFor="server-address">Server address</label>
              <div className="test-connections-div">
              <TextBoxComponent
                value={server}
                onChange={(e: any) => onServerChange(e)}
                onBlur={() => handleServerChange()}
                placeholder="Server Address"
                className="e-input"
              />
              <TooltipComponent position="TopCenter" content="Test Connection" target="#btn-test-connection" height={"auto"} width={"auto"}>
                <img src={checked} id="btn-test-connection" onClick={TestConnectionToServer}/>
              </TooltipComponent>
              </div>
            </div>
            <div className="form-group mb-2">
              <label htmlFor="user-name">User name</label>
              <TextBoxComponent
                value={user}
                onChange={(e: any) => onUserChange(e)}
                placeholder="UserName"
                className="e-input"
              />
            </div>
            
            <div className="form-group mb-2">
              <label htmlFor="pwd">Password</label>
              <TextBoxComponent  
                type="password"
                value={password}
                onChange={(e: any) => onPwdChange(e)}
                placeholder="Password"
                className="e-input"
              /> 
            </div>

            <input
              id="btnlogin"
              type="button"
              value="Connect"
              onClick={ConnectToServer}
              className="btn-open-document mt-4"
            />
          </div>

          <div className="vault-connect">
            <div className="form-group mb-2">
              <label htmlFor="select-vault">Vault</label>
              <DropDownListComponent
                id="ddlelement"
                change={onVaultChange}
                ref={(scope) => {
                  vaultListObject = scope;
                }}
                value={vaultId}
                dataSource={vaultListData}
                placeholder="Select Vault"
                fields={list}
              />
            </div>
            <div className="form-group mb-2">
              <label htmlFor="document-class">Document Class</label>
              <TextBoxComponent
                value={clsName}
                //  onChange={(e) => changeHandler(e)}
                placeholder="Select Document Class"
              // cssClass="e-outline"
              // floatLabelType="Auto"
              />
            </div>
            <div className="form-group mb-2">
              <label htmlFor="select-object">Object</label>
              <DropDownListComponent
                id="ddlelement1"
                change={onDocChange}
                ref={(scope) => {
                  viewReportListObject = scope;
                }}
                value={docId}
                dataSource={viewReportListData}
                placeholder="Select Object"
                fields={list1}
              />
            </div>
            <input
              type="button"
              value="Select"
              onClick={OpenDocument}
              className="btn-open-document mt-4"
              disabled={isLoading}
            />
          </div>
        </div>
        
        <div id="loginFooter" className="connect-login-footer mt-4">
          <div id="info">
            {isError === true  ? (
              <div id="msg">{errorMsg}</div>
            )            
            : (
              <div id="msg"></div>
            )}
          </div>
        </div>
      </div>
  );
};

export default ServerConnection;
